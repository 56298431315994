<template>
  <div>
    <div class="video-wrap">
      <div id="video-box">
        <div id="self-box"/>
      </div>
      <div class="control-btn">
        <ul>

          <li :class="{playing:audioController,pause:!audioController}">
            <span :class="{playing:audioController,pause:!audioController}" class="un"></span>
            <i
                class="iconfont icon-maikefeng"
                style="font-size: 50px"
                @click="controlAudio"/></li>
        </ul>
      </div>
    </div>
    <el-button type="danger" @click="closeHandler">关闭通话</el-button>
  </div>

</template>

<script>
import HRTC from '@/SDK/hrtc';  // 华为实时音视频工具包
import * as CryptoJS from 'crypto-js';  // 加密
import service from "../utils/request";
import {mapState} from "vuex";
import {checkConsultState} from "@/api/counselService";

function getUrlParam(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return (false);
}

export default {
  name: "outside-video",
  data() {
    return {
      ...require('@/assets/app.json'),
      show: false,
      client: null, // 客户端
      // 用户角色
      // 0 => 互动观众（joiner）
      // 1 => 主播（publisher）
      // 2 => 普通观众（player）
      joinRole: 0,
      localStream: null, // 本地流对象
      audioController: true,
      recording: false,
      socket: null,
      userId: '',
      roomId: '',
      ajaxObj: {}
    }
  },
  created() {
    const obj = {
      name: '',
      phone: '',
      sex: '',
      birthday: '',
      age: '',
      deptId: '',
      code: '',
      contentId: '',
      teacherId: '',
      mealId: '',
      price: '',
      namePath: ''
    }
    for (const key in obj) {
      obj[key] = getUrlParam(key)
      if (key == 'name' || key== 'namePath') {
        obj[key] = decodeURI(getUrlParam(key))
      }
    }
    const ajaxObj = {
      customerName: obj.name,
      deptId: obj.deptId,
      contentId: obj.contentId,
      teacherId: obj.teacherId,
      mealId: obj.mealId,
      price: obj.price,
      namePath: decodeURI(obj.namePath),
      consultCustomer: {
        name: obj.name,
        phone: obj.phone,
        sex: obj.sex,
        birthday: obj.birthday,
        age: obj.age,
        deptId: obj.deptId,
        code: obj.code,
      }
    }
    this.ajaxObj = ajaxObj
    this.roomId = obj.teacherId
    this.userId = obj.code
    checkConsultState(obj.teacherId).then(async ({code}) => {
      if (code === 200) {
        const send = {
          roomId: this.roomId,
          userInfo: ajaxObj.consultCustomer,
          toUserId: 'teacher' + this.roomId,
          three: ajaxObj,
          sendObj: {
            roomId: this.roomId,
            teacherId: this.roomId,
            price: obj.price,
            contentId: obj.contentId,
            serviceRecordId: obj.mealId,
          },
          code: 201
        }
        this.socket = new WebSocket(process.env.VUE_APP_SOCKET + `/imserver/customer${obj.code}`)
        this.socket.onopen = () => {
          console.log('socket已开启');
          this.socket.onmessage = this.onMessageReceived
          this.socket.send(JSON.stringify(send))
        }
        await this.localCreateClient()
      }
    }).catch(err => {
      this.$message.error(err.msg + '，请稍后再试')
      // setTimeout(() => {
      //   window.close()
      // }, 1500)
    })


  },
  mounted() {
  },
  methods: {
    onMessageReceived(res) {
      try {
        const payload = JSON.parse(res.data)
        console.log(payload, 'socket');
        if (typeof payload === 'object' && payload.code !== 200) {
          this.$message.error(payload.message + '，请稍后再试')
          // setTimeout(() => {
          //   window.close()
          // }, 1500)
        } else if (payload.code === 200) {
          this.closeHandler()
        }
      } catch (e) {

      }
    },
    sendMessage() {

    },
    //麦克风开关
    controlAudio() {
      this.audioController = !this.audioController
      if (this.audioController) {
        this.localStream.unmuteAudio()
      } else {
        this.localStream.muteAudio()
      }
    },
    // 生成用户信息  加入房间
    generateUserInfo() {
      const ctimeNum = 7200000; // 单位：毫秒
      const ctime1 = Math.floor((ctimeNum + new Date().getTime()) / 1000).toString();
      const message = `${this.appId}+${'three' + this.roomId}+${String(this.userId)}+${ctime1}`;

      return {
        userId: String(this.userId),
        signature: CryptoJS.HmacSHA256(message, this.appSecret).toString(), // 前端加密
        ctime: ctime1,
        role: this.joinRole
      }
    },
    async localCreateClient() {
      this.client = HRTC.createClient({
        appId: this.appId,
        domain: this.domain
      });
      this.joinRoom()
    },
    // 加入房间
    joinRoom() {
      this.client.join(String('three' + this.roomId), this.generateUserInfo()).then(async res => {
        this.subscribeMedia()
        this.createClientStream()
      }).catch(err => {
        console.log(err)
      })
    },

    // 创建本地流并发布  播放本地视频
    createClientStream() {
      const stream = HRTC.createStream({audio: true, video: true})
      stream.initialize().then(async () => {
        stream.addResolution('90p_1')  //可选的，如果要开启双流可以添加另外一个分辨率的视频
        this.client.publish(stream)
        stream.play('self-box', {muted: false})
        this.localStream = stream
      }).catch(res => {
        // stream.play('self-box', {muted: false})   //播放本地流
      }).catch(err => {
        this.$confirm('查询设备失败，请检查麦克风或者摄像头', '警告', {
          confirmButtonText: "确定",
          showCancelButton: false,
          callback: () => {
            // this.closeCall()
          }
        })
      })
      this.localStream = stream
    },

    // 订阅远端媒体 播放远端视频
    subscribeMedia() {
      this.client.on('stream-added', async (event) => {

        const steam = event.stream
        this.client.subscribe(steam, {video: true, audio: true})

      })
      this.client.on('stream-subscribed', (event) => {
        const stream = event.stream
        stream.play('video-box', {objectFit: 'contain', muted: false})
      })
      this.client.on('peer-leave', (event) => {
        // just do something…
        event.stream.close()
        this.client.leave();

      })
      this.client.on('stream-removed', (event) => {
        event.stream.close()
        // this.closeCall()
      })
    },
    closeHandler() {
      this.$confirm('咨询是否已经结束？', '提示').then(() => {
        this.closeCall()
      })
    },
    // 关闭通话
    closeCall() {
      this.client.leave();
      this.show = false
      this.localStream.close()
      this.localStream = null
      window.close()
    }
  },
  beforeDestroy() {
    this.socket.close()
    this.socket = null
  }
}
</script>

<style lang="scss" scoped>
.control-btn {
  margin-left: 10px;
  float: left;
  width: 100px;
  height: 300px;

  li {
    width: 60px;
    height: 50px;
    border: 1px solid #373737;
    border-radius: 5px;
    margin-top: 10px;
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
}

.video-wrap {
  overflow: hidden;
  background: #000000;
  border-radius: 10px;
  border-right: 1px solid #8A8A8A;
}

#video-box {
  float: left;
  position: relative;
  width: calc(100% - 110px);
  height: calc(100vh - 100px);
  background: tomato;
  border-radius: 10px;
}

#self-box {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 200px;
  height: 200px;
  background: pink;
  z-index: 999;
  border-radius: 10px;
}

.li {
  width: 100%;
  height: 500px;
  overflow-y: auto;
  padding: 30px;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #909399;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #131313;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #434343;
  }
}

.iconfont {
  &:active {
    opacity: .78;
  }

}

.el-button {
  margin: 10px auto;
  display: block;
}

.pause {
  color: $danger-color;
}

.playing {
  color: $success-color;
}

.un {
  display: inline-block;
  width: 4px;
  height: 50px;
  position: absolute;
  transform: rotateZ(45deg);
  border-radius: 2px;
  transition: all .15s linear;

  &.pause {
    background-color: $danger-color;
    left: 50%;
    bottom: 0;
    transform: rotateZ(45deg) translateX(-50%);
  }

  &.playing {
    right: -25px;
    top: -50px;
    background-color: $success-color;
  }
}
</style>