<template>
  <div class="login">
    <div class="login-box">
      <span class="login-box-title">登录</span>
      <span class="login-box-sub-title">欢迎您，来到咨询系统！</span>
      <el-form :model="form">
        <el-form-item prop="username">
          <el-input v-model="form.username" prefix-icon="el-icon-user"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="form.password" prefix-icon="el-icon-suitcase" type="password"></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="login">登录</el-button>
    </div>
  </div>
</template>

<script>
import { goLogin } from '@/api/home';
import { formatPwd } from '@utils';
export default {
  name: "login",
  data() {
    return {
      form: {
        username: 'admin',
        password: 'A189F8145CBA4BAAE036CAD1B332WD0CD',
        grant_type: 'password',
        client_id: 'admin-app',
        client_secret: 'B1538757C07E6A6AD2D4F71A4986E6B6',
        relogin: 1
      }
    }
  },
  methods:{
    login() {
      console.log(222);
      // 加盐
      this.form.password = formatPwd(this.form.password);
      goLogin(this.form).then(res => {
      })


      // this.$router.push({path:'/'})
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10vw;
  box-sizing: border-box;

  &-box {
    width: 500px;
    background: #FFFFFF;
    border: 1px solid #E9E9E9;
    opacity: 1;
    border-radius: 10px;
    padding: 30px 42px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    &-title {
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #4E6FDA;
      margin-bottom: 14px;
      letter-spacing: 5px;
    }

    &-sub-title {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #808080;
      margin-bottom: 48px;
    }

    .el-form {
    }

    .el-button {
      width: 240px;
      margin: 20px auto 0;
    }
  }
}
</style>
