import request from "../utils/request";

// 获取咨询师列表数据
export const getCounselList = data => {
    return request({
        url: '/cs/content/page',
        method: 'POST',
        data
    })
}
//查询咨询师详情
export const teacherDetail = id => {
    return request({
        url: "/cs/content/get-id?id=" + id
    })
}
// 咨询房间信息添加 接口
export const createConsultRoom = data => request({url: '/cs/room/add', method: 'POST', data});

// 购买服务接口   请求参数 {buyNum：购买次数，customerId：购买人id，就是登录用户id，购买服务的：contentId}
export const buyConsultService = data => request({url: '/cs/service/add', method: 'POST', data});

// 查询用户是否购买过该服务接口  参数 （contentId：服务id，登录人：customerId）
export const isBuyService = params => {
    return request({url: '/cs/service/get-status', method: 'GET', params})
};
//查询咨询师状态
export const checkConsultState = data => {
    return request({
        url: "/cs/teacher/get-status?teacherId=" + data,
        method: "get"
    })
}
